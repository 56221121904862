/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState } from 'react';
import { Header, BackToTop, Footer, SEO, Loading } from '@components';
import { Layout } from '@layouts';
import * as Yup from 'yup';
import axios from 'axios';
import { config } from '../../config';

/** Yup form validation for the edit profile form */
const ContactFormValidation = Yup.object().shape({
	name: Yup.string().required('Please enter your name'),
	email: Yup.string()
		.required('Please enter your email')
		.email('Please enter a valid email address'),
	subject: Yup.string().required('Please enter a subject'),
	message: Yup.string().required('Please enter your message'),
});

/**
 * The contact page
 */
const ContactPage = () => {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');
	const [fName, setFName] = useState('');
	const [error, setError] = useState('');

	/** Submit the contact form message */
	const submitMessage = async (e: any) => {
		e.preventDefault();
		setLoading(true);
		setError('');
		setSuccess(false);

		/** Gather form data */
		const formData = {
			formName: 'Contact',
			name,
			email,
			fName,
			subject,
			message,
		};

		try {
			console.log('Message submitting', formData);

			// Validate the form
			await ContactFormValidation.validate(formData);

			const result = await axios({
				url: `${config.api.endpoint}/notification/forms/sanishield/`,
				method: 'POST',
				data: formData,
			});

			// Set success and clear form
			setName('');
			setEmail('');
			setFName('');
			setSubject('');
			setMessage('');
			setSuccess(true);

			console.log('RESULT', result);
		} catch (err) {
			console.error('Error submitting form:', err);

			let errorMessage = err?.errors;
			// Extract a single error from the array
			if (errorMessage?.length) [errorMessage] = errorMessage;
			// If we don't have an error message, make a generic one
			if (!errorMessage)
				errorMessage =
					'It looks like a problem occurred while submitting your form. Please try again later.';

			setError(errorMessage);
		}

		setLoading(false);
	};

	return (
		<Layout>
			<SEO
				slug='contact'
				title='SaniShield | Contact Us'
				description='Contact the SaniShield team.'
			/>
			<Header activeRoute='contact' />

			<div className='ttm-bgcolor-grey'>
				<div className='ttm-page-title-row-inner'>
					<div className='container'>
						<div className='row align-items-center'>
							<div className='col-lg-12'>
								<div className='page-title-heading'>
									<h2 className='title'>Contact Us</h2>
								</div>
								<div className='breadcrumb-wrapper'>
									<span>
										<a title='Homepage' href='/'>
											Home
										</a>
									</span>
									<span>Contact Us</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='site-main'>
				<section className='ttm-row pt-0 pb-50 clearfix'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-8 offset-lg-2'>
								<div className='ttm-col-bgcolor-yes ttm-bg ttm-bgcolor-white z-index-2 spacing-5 box-shadow'>
									<div className='ttm-col-wrapper-bg-layer ttm-bg-layer' />
									<div className='section-title with-desc clearfix'>
										<div className='title-header'>
											<h5>GET IN TOUCH</h5>
											<h2 className='title'>We're Here to Answer Your Questions</h2>
										</div>
									</div>

									<form className='wrap-form clearfix' onSubmit={submitMessage}>
										<div className='row' id='basic-row'>
											<div className='col-lg-6'>
												<label>
													<span className='text-input'>
														<input
															id='name'
															name='name'
															type='text'
															value={name}
															onChange={(event: any) => setName(event?.target?.value)}
															autoComplete='on'
															placeholder='Your Name'
															required
														/>
													</span>
												</label>
											</div>
											<div className='col-lg-6'>
												<label>
													<span className='text-input'>
														<input
															id='email'
															name='email'
															type='email'
															value={email}
															onChange={(event: any) => setEmail(event?.target?.value)}
															autoComplete='on'
															placeholder='Your Email'
															required
														/>
													</span>
												</label>
											</div>
										</div>
										<div className='row' id='fname-row'>
											<div className='col-lg-12'>
												<label>
													<span className='text-input'>
														<input
															id='fName'
															name='fName'
															type='text'
															value={fName}
															onChange={(event: any) => setFName(event?.target?.value)}
															placeholder='First Name'
															autoComplete='off'
														/>
													</span>
												</label>
											</div>
										</div>
										<div className='row' id='subject-row'>
											<div className='col-lg-12'>
												<label>
													<span className='text-input'>
														<input
															id='subject'
															name='subject'
															type='text'
															value={subject}
															onChange={(event: any) => setSubject(event?.target?.value)}
															placeholder='Subject'
															required
														/>
													</span>
												</label>
											</div>
										</div>
										<label>
											<span className='text-input'>
												<textarea
													id='message'
													name='message'
													rows={3}
													value={message}
													onChange={(event: any) => setMessage(event?.target?.value)}
													placeholder='Message'
													required
												/>
											</span>
										</label>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											{/* Show loading gif if we're loading */}
											{loading && <Loading sx={{ mx: '10px' }} />}
											{/* Show button if we're not loading */}
											{!loading && (
												<button
													className='submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor'
													style={{ width: 'fit-content' }}
													type='submit'
												>
													Send Message
												</button>
											)}
											{error && (
												<span style={{ color: '#cc0000', zIndex: 100, marginTop: '10px' }}>
													{error}
												</span>
											)}
											{success && (
												<span style={{ color: '#00cc36', zIndex: 100, marginTop: '10px' }}>
													Your message was sent successfully!
												</span>
											)}
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<Footer />

			<BackToTop />
		</Layout>
	);
};

export default ContactPage;
